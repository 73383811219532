<template>
  <div class="register">
    <v-card class="my-auto" max-width="480px">
      <v-card-subtitle class="text-center">
        <h1 class="primary--text text-center my-6">App Rádio Testemunhal</h1>
        <span> Informe seu dados para se cadsatrar no App </span>
      </v-card-subtitle>

      <v-card-text>
        <v-form class="card-form" ref="registerForm" v-model="isValid" laze-validation>
          <v-text-field
            v-model="userName"
            :counter="50"
            color="primary"
            label="Nome de Usuário"
            :rules="[(v) => !!v || 'Um nome de usuário é obrigatório']"
            required
          ></v-text-field>
          <v-text-field
            v-model="email"
            color="primary"
            label="E-mail"
            type="email"
            :rules="[
              (v) => !!v || 'O E-mail é obrigatório',
              (v) => /.+@.+\..+/.test(v) || 'E-mail inválido'
            ]"
            required
          ></v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="passwordField ? 'mdi-eye' : 'mdi-eye-off'"
            :type="passwordField ? 'text' : 'password'"
            :rules="[
              (v) => !!v || 'A senha é obrigatória',
              (v) => v.length >= 6 || 'A senha precisa terno mínimo 6 digitos'
            ]"
            label="Senha"
            required
            counter
            color="primary"
            @click:append="passwordField = !passwordField"
          ></v-text-field>
          <v-text-field
            v-model="confirm"
            :append-icon="confirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="confirmPassword ? 'text' : 'password'"
            :rules="[
              (v) => !!v || 'Campo obrigatório',
              (v) => v === this.password || 'As senhas precisam ser iguais'
            ]"
            name="confirmPassword"
            label="Confirmar Senha"
            counter
            color="primary"
            @click:append="confirmPassword = !confirmPassword"
          ></v-text-field>

          <v-alert class="mb-0 mt-4" dense outlined type="error" v-if="errorFeedBack">
            {{ errorFeedBack }}
          </v-alert>
        </v-form>
      </v-card-text>

      <v-card-actions class="px-6">
        <v-btn
          class="btn-primary mt-4"
          color="primary"
          block
          dark
          :loading="btnLoading"
          @click="doRegister()"
        >
          Entrar
        </v-btn>
      </v-card-actions>

      <v-card-text>
        <div class="text-center mb-4">
          Já tem cadastro?
          <router-link class="primary--text" :to="{ name: 'Login' }">
            Clique e faça o login.
          </router-link>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { auth, createUserWithEmailAndPassword } from '@/firebase/firebase'

  export default {
    name: 'Register',

    data: () => ({
      isValid: false,
      passwordField: false,
      confirmPassword: false,
      errorFeedBack: null,
      btnLoading: false,
      userName: null,
      email: null,
      password: '',
      confirm: ''
    }),

    methods: {
      doRegister() {
        if (this.$refs.registerForm.validate()) {
          this.btnLoading = true
          const newUser = {
            name: this.userName,
            email: this.email,
            role: 'op'
          }

          createUserWithEmailAndPassword(auth, this.email, this.password)
            .then((userCredential) => {
              // console.log(userCredential);
              newUser.id = userCredential.user.uid
              this.$store
                .dispatch('addUserToFirestore', newUser)
                .then(() => {
                  this.$router.push({ name: 'Home' })
                })
                .catch((err) => {
                  console.log(err)
                  this.btnLoading.false
                })
            })
            .catch((error) => {
              console.log(error)
              this.email = ''
              this.errorFeedBack = error.message
              this.btnLoading = false
            })
        }
      }
    }
  }
</script>

<style>
  .register {
    display: flex;
    justify-content: center;
    height: 90vh;
  }
</style>
