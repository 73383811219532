var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('v-card',{staticClass:"my-auto",attrs:{"max-width":"480px"}},[_c('v-card-subtitle',{staticClass:"text-center"},[_c('h1',{staticClass:"primary--text text-center my-6"},[_vm._v("App Rádio Testemunhal")]),_c('span',[_vm._v(" Informe seu dados para se cadsatrar no App ")])]),_c('v-card-text',[_c('v-form',{ref:"registerForm",staticClass:"card-form",attrs:{"laze-validation":""},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-text-field',{attrs:{"counter":50,"color":"primary","label":"Nome de Usuário","rules":[function (v) { return !!v || 'Um nome de usuário é obrigatório'; }],"required":""},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('v-text-field',{attrs:{"color":"primary","label":"E-mail","type":"email","rules":[
            function (v) { return !!v || 'O E-mail é obrigatório'; },
            function (v) { return /.+@.+\..+/.test(v) || 'E-mail inválido'; }
          ],"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"append-icon":_vm.passwordField ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.passwordField ? 'text' : 'password',"rules":[
            function (v) { return !!v || 'A senha é obrigatória'; },
            function (v) { return v.length >= 6 || 'A senha precisa terno mínimo 6 digitos'; }
          ],"label":"Senha","required":"","counter":"","color":"primary"},on:{"click:append":function($event){_vm.passwordField = !_vm.passwordField}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"append-icon":_vm.confirmPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.confirmPassword ? 'text' : 'password',"rules":[
            function (v) { return !!v || 'Campo obrigatório'; },
            function (v) { return v === this$1.password || 'As senhas precisam ser iguais'; }
          ],"name":"confirmPassword","label":"Confirmar Senha","counter":"","color":"primary"},on:{"click:append":function($event){_vm.confirmPassword = !_vm.confirmPassword}},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}}),(_vm.errorFeedBack)?_c('v-alert',{staticClass:"mb-0 mt-4",attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.errorFeedBack)+" ")]):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"px-6"},[_c('v-btn',{staticClass:"btn-primary mt-4",attrs:{"color":"primary","block":"","dark":"","loading":_vm.btnLoading},on:{"click":function($event){return _vm.doRegister()}}},[_vm._v(" Entrar ")])],1),_c('v-card-text',[_c('div',{staticClass:"text-center mb-4"},[_vm._v(" Já tem cadastro? "),_c('router-link',{staticClass:"primary--text",attrs:{"to":{ name: 'Login' }}},[_vm._v(" Clique e faça o login. ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }